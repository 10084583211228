/* Extra */
/* body {
    color: #272727;
    font-size: 14px;
    margin: 0;
  } */
  
  
  .navbarx {
    position: sticky; 
    top: 0;
    align-items: center;
    background: black;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    font-family: sans-serif;
    padding: 10px 50px;
    opacity: 0.7;
    z-index: 10;
  }
  
  .push-left {
    margin-left: auto;
  }
  
  /* Menu */
  .hamburgerx {
    background: transparent;
    border: none;
    cursor: pointer;
    display: none;
    outline: none;
    height: 30px;
    position: relative;
    width: 30px;
    z-index: 1000;
  }


  .menu-active{
    overflow: hidden !important;
  }



  .hamburger-line {
    background: #fdfbfb;
    height: 3px;
    position: absolute;
    left: 0;
    transition: all 0.2s ease-out;
    width: 100%;
  }
  .hamburgerx:hover .hamburger-line {
    background: #fdfbfb;
  }

  .hamburger-line-top {
    top: 3px;
  }
  .menu-active .hamburger-line-top {
    top: 50%;
    transform: rotate(45deg) translatey(-50%);
  }
  .hamburger-line-middle {
    top: 50%;
    transform: translatey(-50%);
  }
  .menu-active .hamburger-line-middle {
    left: 50%;
    opacity: 0;
    width: 0;
  }
  .hamburger-line-bottom {
    bottom: 3px;
  }
  .menu-active .hamburger-line-bottom {
    bottom: 50%;
    transform: rotate(-45deg) translatey(50%);
  }
  
  .nav__link{
    color: rgb(251, 243, 243);
    opacity: 0.8;
    text-decoration: none !important;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    color: inherit !important;
  }

/*   .nav__link, .nav__link:hover, .nav__link:focus, .nav__link:active {
    text-decoration: none !important;
    color: inherit !important;
} */
  

  .nav-menu {
    display: flex;
    list-style: none;
    transition: all 0.25s ease-in;
    margin-right: 100px;
    margin-top: 50px;
  }

  .nav-menu .menu-item .nav__link {
    color: #444444;
    display: block;
    line-height: 30px;
    margin: 0px 20px;
    text-decoration: none;
  }

  
  .sub-nav {
    border: 1px solid #ccc;
    display: none;
    position: absolute;
    background-color: #fff;
    padding: 5px 5px;
    list-style: none;
    width: 230px;
  }
  @media screen and (max-width: 1200px) {
    .nav-menu{
      margin-right: 0 !important;
    }

    .nav-menu .menu-item .nav__link{
      margin: 0px 10px;
    }
    
    .navbarx{
      padding: 10px 30px;
    }

  }

  @media screen and (max-width: 820px) {
    .nav-menu {
      background: black;
      flex-direction: column;
      justify-content: center;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translatey(-100%);
      text-align: center;
      height: 100%;
      z-index: 10;

      margin-top: 0px !important;
      
    }

    .navbarx{
      position: static !important;
      opacity: 1 !important;
    }

    .lalit-img{
      width: 200px;
      height: 120px;
    }



    .menu-active .nav-menu {
      transform: translatey(0%);
      opacity: 1;
      height: 100%;
      width: 100%;
    }
  }

  @media screen and (max-width: 820px) {
    .nav-menu .menu-item .nav__link {
      font-size: 20px;
      margin: 8px;
    }
  }

  @media screen and (max-width: 820px) {
    .hamburgerx {
      display: inline-block;
    }
  }


  @media screen and (max-width: 820px) {
    .sub-nav {
      position: relative;
      width: 100%;
      display: none;
      background-color: rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
    }
  }


  @media screen and (max-width: 440px) {
    .navbarx{
      padding-left: 0px;
      padding-right: 30px !important;
    }

    .lalit-img{
      width: 150px;
      height: 90px;
    }


  }
  