/* ID  */



/* TAG */
ul{
  padding: 0;
  list-style-type: none;
}


/* CLASS */

.container-about-1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 6rem;
  }

  .box-about-1{
    font-size: 30px;
    flex-basis: 50%;
    padding: 2rem;
    animation: down-to-top 2s ease-in-out;
  }
  
  .box-about-2{
    flex-basis: 30%;
  }

  .profile-about-pic{
    width: 25rem;
    animation: scaleOut 2s ease-in-out;
  }

  .title-about-paragraph{
    font-family: 'Quicksand', sans-serif;
    opacity: 0.8;
  }

.container-about-2{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 6rem;
  font-family: 'Quicksand', sans-serif;
}

.box-about-3{
  flex-basis: 25%;
  opacity: 0.6;
  padding-left:5.5em ;
  animation: left-to-right 2s ease-in-out;
}

.box-about-4{
  font-size: 19px;
 flex-basis: 900px;
   padding-left: 2em;
  padding-right: 2em;
  opacity: 0.7;
  animation: right-to-left 2s ease-in-out;
}

.container-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}





@media(max-width:1200px){
  .box-about-3{
    flex-basis: 900px !important;
    padding-left: 2em;
    margin-bottom: 18px !important;
  }
}



  @media(max-width:820px){
    .profile-about-pic{
        width: 20rem;
      }

    .box-about-1{
        font-size: 20px;
        margin-top: 3em;
        flex-basis: 80%;
        padding: 0rem;
    }
    .title-about-paragraph{
        text-align: center;
      }

  }


  @media(max-width:400px){
    .box-about-1{
        font-size: 20px !important;
    }
    .profile-about-pic{
        width: 15rem;
      }
      .title-about-paragraph{
       font-size: 17px;
      }

      .box-about-4{
        font-size: 15px;
      }

  }