input{
    background-color: black !important;
    color: white !important;
    /* border: solid !important; */
    border-color: white !important;
    border-top-width: 0px !important;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-radius: 0px !important;
  }

  
  input:focus{
    box-shadow: none !important;
    border-bottom-color: #689af8 !important;
  
  }
  
  input:focus + label{
    color:#689af8 !important;
  }


  textarea:focus{
    box-shadow: none !important;
    border-bottom-color: #689af8 !important;
  
  } 

  textarea:focus + label{
    color:#689af8 !important;
  }


textarea{
  resize: none;
height:10em !important;
color: white !important;
background-color: black !important;
border-color: white !important;
border-top-width: 0px !important;
border-left-width: 0px !important;
border-right-width: 0px !important;
border-radius: 0px !important;
}


.touch{
  font-family: 'Oswald', sans-serif;
  font-size: 3em;
  margin-bottom: 0.5em;
  animation: left-to-right 2s ease-in-out;

}

.col-sm-12{
  animation: down-to-top 2s ease-in-out;
}

.contact-form{
  position: relative !important;
  width: 80%;
  height: auto;
  margin-bottom: 8em;
  font-family: 'Quicksand', sans-serif;
}

#cin{
	overflow: auto;
	overflow-x: hidden;
}

#cin::-webkit-scrollbar-track{
	background-color: black;
}

#cin::-webkit-scrollbar{
	width: 6px;
	background-color: #F5F5F5;
}

#cin::-webkit-scrollbar-thumb{
	border-radius: 100px;
	background-color: #545454;
}



.connect{
  margin-top: 3em;
  margin-bottom: 3em;
  font-size: 14px;
  width:150px;
    background-color: white !important;
    color: black !important;
    border-radius: 0px !important;
    position: absolute;
    right:10px;




  }

  .paperPlane{
    margin-left: 5px;
  }

  .comment-form{
    font-family: 'Mohave', sans-serif;
    margin-top: 5em;
  }

  @media(max-width:820px){
    .contact-form{
      width: 100%;
    }
  }

/*   @media(max-width:400px){
    .connect{
      width: 140px;
    }


  } */