.experience{
    margin: 0 auto;
    position: relative;
    height: auto;
}



.experience-box-1{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 1em;
    
    
}

.experience-img{
    opacity: 0.6;
    transition: transform 5s ease-in-out;
}

.experience-img:hover {
    transform: rotate(720deg);
}


.experience-caption{
    font-family: 'Oswald', sans-serif;
    font-size: 4em !important;
    font-weight: 700;
    position: absolute;
    max-width: 400px;
    animation: down-to-top 2s ease-in-out;
}

.v{
    height: 50px !important;
    margin: 0 auto;
    display: block;
    margin-bottom: 8em;
    animation: MoveUpDown 1s linear infinite;
    
}


@keyframes MoveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(30px);
    }
  }

  @media(max-width:440px){
    .experience-img{
        width: 250px !important;
        height: 250px !important;

    }
    .experience-caption{
        font-size: 2.5em !important;
        font-weight: 500;
    }
    .v{
        height: 25px !important;
        width: 25px !important;
    }
  }